import React from 'react';
import {Button} from "@mui/material";
import '../css/categories.css';
const CategorySelector = ({ onSelectCategory }) => {
    return (
        <div>
            <h1 id="Work">Mes projets</h1>
            <div className="button-container"> {}
                <Button onClick={() => onSelectCategory('Web')}>Projets Web, PHP</Button>
                {/*<Button onClick={() => onSelectCategory('Java')}>Projets Web, JAVA</Button>*/}
                <Button onClick={() => onSelectCategory('Python')}>Projets en Python</Button>
                <Button onClick={() => onSelectCategory('C')}>Projets en C</Button>
            </div>
        </div>
    );
};

export default CategorySelector;
