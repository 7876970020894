import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdWork } from "react-icons/md";
import { IoIosSchool } from "react-icons/io";
import 'react-bootstrap';

function MyTimeline() {
    return (
        <div>
        <h1> Mon parcours </h1>
        <VerticalTimeline>

            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="Sept. 2024 - Sept. 2026"
                iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                icon={<MdWork />}
            >
                <h3 className="vertical-timeline-element-title">Chef de projet informatique</h3>
                <h4 className="vertical-timeline-element-subtitle">Agence nationale de la recherche, Paris 13e</h4>
                <p className="text-sm-left">
                    Conception, développement et maintenance de solutions web avec PHP/Symfony, gestion de projets, coordination d'équipes, et rédaction de documentation technique.
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="2024 - 2026"
                iconStyle={{background: 'rgb(233, 30, 99)', color: '#fff'}}
                icon={<IoIosSchool/>}
            >
                <h3 className="vertical-timeline-element-title">Master MIAGE</h3>
                <h4 className="vertical-timeline-element-subtitle">Université de Nanterre</h4>
                <p className="text-sm-left">
                    Spécialité Systèmes d’Information fiables et intelligence des données.
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="Sept. 2022 - Sept. 2024"
                iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                icon={<MdWork />}
            >
                <h3 className="vertical-timeline-element-title">Développeur web</h3>
                <h4 className="vertical-timeline-element-subtitle">
                    <span className="company-name">Maison Richard</span>, Gennevilliers
                </h4>
                <p className="text-sm-left">
                    Internaliser les compétences de développement Web afin de garantir la maintenance applicative
                    des sites et leurs évolutions.
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="2023 - 2024"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<IoIosSchool />}
            >
                <h3 className="vertical-timeline-element-title">Licence Mathématiques et Informatique appliquées aux SHS [MIAGE]</h3>
                <h4 className="vertical-timeline-element-subtitle">Université de Nanterre</h4>
                <p>
                    Diplômé avec mention.
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="2022 - 2023"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<IoIosSchool />}
            >
                <h3 className="vertical-timeline-element-title">Licence App & Data</h3>
                <h4 className="vertical-timeline-element-subtitle">Université de Versailles Saint-Quentin-en-Yvelines, Paris-Saclay</h4>
                <p>
                    Diplômé avec distinction, en me classant deuxième de ma promotion.
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="2020 - 2022"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<IoIosSchool />}
            >
                <h3 className="vertical-timeline-element-title">BTS Services informatiques aux organisations (SLAM)</h3>
                <h4 className="vertical-timeline-element-subtitle">Lycée Jules-Ferry, Conflans-Sainte-Honorine</h4>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="2017 - 2020"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<IoIosSchool />}
            >
                <h3 className="vertical-timeline-element-title">Bac Pro Système Numérique, Options Réseaux informatique et systèmes communicants</h3>
                <h4 className="vertical-timeline-element-subtitle">Lycée Claude Chappe, Nanterre</h4>
                <p>
                    Diplômé avec mention Bien.
                </p>
            </VerticalTimelineElement>
        </VerticalTimeline>
        </div>
    );
}

export default MyTimeline;
