import React, { useState } from 'react';
import './css/App.css';
import Intro from './jsx/Intro';
import Header from "./jsx/Header";
import { Container } from "@mui/material";
import Footer from "./jsx/Footer";
import Experience from "./jsx/Experience";
import ProjectsDisplay from './jsx/Projects/ProjectsDisplay';
import Contact from "./jsx/Contact";

function App() {


    return (
        <div>
            <Container maxWidth="md">
                <Header />
                <Intro />
                <Experience />
                <ProjectsDisplay />
                <Contact />
            </Container>

            <Container maxWidth="md">
                <Footer />
            </Container>
        </div>
    );
}

export default App;
