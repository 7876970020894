import React, {useState} from 'react';
import CategorySelector from '../CategorySelector';
import ProjectCard from './ProjectCard';
import '../../css/card.css';
import pastriesImage from '../../img/projects/web/pastries.png';
import pastriesImage2 from '../../img/projects/web/locar.png';
import pastriesImage3 from '../../img/projects/web/davidson.png';
import pastriesImage4 from '../../img/projects/web/habitaconnect.png';
import pastriesImage5 from '../../img/projects/web/taskflowui.png';
import pastriesImage6 from '../../img/projects/web/LOCAR-JAVA.png';
import pastriesImage7 from '../../img/projects/algo_genetic.png';
import pastriesImage8 from '../../img/projects/algo_tri.png';
import pastriesImage9 from '../../img/projects/web/tw3.png';
import graphTheory from '../../img/projects/python/graph.png'

const ProjectsDisplay = () => {
    const [selectedCategory, setSelectedCategory] = useState('Web');

    const projects = [
        // PROJETS WEB
        {
            category: "Web",
            title: "TW3",
            link: "https://tw3.fr",
            description: "TW3 est un site dédié à l'association Ta3alamaWa3alama. " +
                "\n Cette association a pour mission d'aider les lycéens à intégrer les meilleures écoles et universités en France. " +
                "\n Elle vise à former des intellectuels en promouvant des valeurs d'excellence et de fraternité.",
            imageURL: pastriesImage9,
            technologies: ["PHP", "CSS", "HTML", "Tailwind"]
        },
        {
            category: "Web",
            title: "TaskFlowUI",
            description: "TaskFlowUI est une application web d'exécution de tâches crons sur Symfony 7. L'application est toujours en cours de développement et est maintenanue.\n",
            imageURL: pastriesImage5,
            technologies: ["Symfony", "PHP", "CSS", "HTML", "2FA", "Admin"]
        },
        {
            category: "Web",
            title: "Delicake",
            link: "https://delicake.sami-bahij.com/",
            description: "DeliCake est une plateforme dédiée à la commande de pâtisseries, élaborée avec Symfony 6.2.\n" +
                "\ncompte admin : 43012239@parisnanterre.fr" +
                "\nmot de passe : 43012239",
            imageURL: pastriesImage,
            technologies: ["Symfony", "PHP", "CSS", "HTML"]
        },
        {
            category: "Web",
            title: "Locar",
            link: "https://locar.sami-bahij.com/",
            description: "Locar est une application web de location de voitures développée avec le Framework Symfony en 6.2. \n" +
                "\ncompte admin : 43012239@parisnanterre.fr \n" +
                "mot de passe : 43012239",
            imageURL: pastriesImage2,
            technologies: ["Symfony", "PHP", "CSS", "HTML"]
        },
        {
            category: "Web",
            title: "Davidson Flower",
            link: "https://davidson-flower.sami-bahij.com/",
            description: "Davidson Flower est une application web basée sur Symfony 6.2, réalisé durant la nuit de l'informatique." +
                "Le site a pour but d'apporter une traduction complète dans différentes langues. Pour réaliser la traduction j'ai utilisé l'API de Microsoft Azure.\n" +
                "\ncompte admin : 43012239@parisnanterre.fr" +
                "\nmot de passe : 43012239",
            imageURL: pastriesImage3,
            technologies: ["Symfony", "PHP", "CSS", "HTML", "Microsoft"]
        },
        {
            category: "Web",
            title: "HabitaConnect",
            link: "https://habitaconnect.sami-bahij.com/",
            description: "HabitaConnect est une application de location immobilière de courte durée entre particuliers développé en Laravel 10. \n" +
                "\ncompte utilisateur : 43012239@parisnanterre.fr" +
                "\nmot de passe : 43012239",
            imageURL: pastriesImage4,
            technologies: ["Laravel", "PHP", "CSS", "HTML"]
        },
        // PROJETS EN JAVA
        {
            category: "Java",
            title: "Locar Java",
            link: "https://youtu.be/sx81_pB6BW8",
            description: "Locar est une application web de location de voitures développée en Java avec le Framework Springboot.",
            imageURL: pastriesImage6,
            technologies: ["Java", "Springboot", "HTML", "CSS", "Tomcat"]
        },
        // PROJETS EN PYTHON
        {
            category: "Python",
            title: "Etude sur la diversité des problèmes en théorie des graphes",
            description: "Ce projet universitaire explore des problèmes complexes en théorie des graphes," +
                " en utilisant Python et " +
                "des bibliothèques spécialisées comme NetworkX. Il met en lumière l'utilisation des graphes pour" +
                " résoudre des défis pratiques dans divers domaines, avec une approche centrée sur des solutions" +
                " algorithmiques pour des problèmes NP-complets.",
            imageURL: graphTheory,
            technologies: ["Python", "Stats"],
            pdfUrl: "/pdf/Rapport_graphe_et_open_data.pdf",
        },
        // PROJETS EN C
        {
            category: "C",
            title: "Projet  de comparaison du temps d'exécution de différents algorithmes de tri",
            description: "Dans le cadre de mon étude comparative sur la performance des algorithmes de tri, " +
                "j'ai mené des tests approfondis sur sept algorithmes différents en utilisant une variété " +
                "de données générées selon des critères spécifiques. Les résultats clés indiquent que le tri " +
                "à bulles, bien qu'algorithmiquement simple, affiche les performances les plus faibles dans" +
                " presque tous les scénarios testés. À l'opposé, le tri Shell et le tri par insertion se " +
                "sont montrés robustes et efficaces sur une large gamme de données. Ces découvertes soulignent " +
                "l'importance de la sélection rigoureuse d'un algorithme de tri adapté à la nature des données " +
                "traitées, qui est cruciale pour optimiser les performances informatiques.",
            imageURL: pastriesImage8,
            technologies: ["Python", "Stats"],
            github: "https://github.com/Saami783/algo-c",
            pdfUrl: "/pdf/Algo_de_tri.pdf",
            youtube: "https://youtu.be/YmkygpytLQM"
        },
        {
            category: "C",
            title: "Algorithme génétique - Set Cover",
            description:
                "Les algorithmes génétiques (AG) sont des heuristiques d'optimisation et de recherche " +
                "qui imitent le processus de sélection naturelle et de génétique pour résoudre des " +
                "problèmes complexes. Il s'agit d'un problème" +
                "NP-complet classique dans le domaine de l'informatique théorique. \n\n" +
                "Le paradigme des algorithmes génétiques repose sur le principe de l'évolution " +
                "darwinienne et l'héritage génétique. Dans le contexte du problème du Set Cover, " +
                "l'AG cherche à développer une population de solutions potentielles qui " +
                "évoluent au fil des générations. Chaque individu dans la population " +
                "représente une sous-famille de S, avec l'objectif de minimiser le nombre de " +
                "sous-ensembles, afin de couvrir tous les éléments de l'ensemble U.",
            imageURL: pastriesImage7,
            technologies: ["Biologie", "Binary"],
            github: "https://github.com/Saami783/algo-genetique-set-cover",
            pdfUrl: "/pdf/Algo_genetique_set_cover.pdf",
            youtube: "https://youtu.be/2Pt9p1CcXiA"
        },
        {
            category: "C",
            title: "Algorithme génétique - Branchement",
            description: "La méthode de branchement (Branch and Bound => B&B) " +
                "est une approche algorithmique pour résoudre des problèmes d'optimisation " +
                "combinatoire, tels que le problème du Set Cover. Contrairement " +
                "aux algorithmes génétiques, qui sont des heuristiques probabilistes, B&B " +
                "est une méthode exacte qui garantit de trouver la solution optimale.",
            imageURL: pastriesImage7,
            technologies: ["Biologie", "Binary"],
            github: "https://github.com/Saami783/algo-genetique-branchement",
            pdfUrl: "/pdf/Algo_genetique_bound_and_branch.pdf",
            youtube: "https://youtu.be/zuyqUq_y0mg"
        },
    ];

    const filteredProjects = projects.filter(project => project.category === selectedCategory);

    return (
        <div>
            <CategorySelector onSelectCategory={setSelectedCategory}/>
            <div className="projects-container"> {}
                {filteredProjects.map(project => (
                    <div className="project-card" key={project.id}> {}
                        <ProjectCard project={project}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjectsDisplay;
