import React from 'react';
import { Button, Link } from '@mui/material';

function Contact() {
    return (
        <div style={{textAlign: 'center', marginTop: '20px'}}>
            <h1 id="Contact">Contactez-moi</h1>
            <p>N'hésitez pas à m'envoyer un e-mail si vous avez des questions ou si vous souhaitez collaborer sur un
                projet.</p>
            <Button
                variant="contained"
                color="primary"
                href="mailto:sami.bahij1@gmail.com?subject=Contact%20via%20Portfolio&body=Bonjour%20Sami,%20"
            >
                Envoyer un e-mail
            </Button>
            <p style={{marginTop: '20px', fontSize: '0.9em', color: '#666'}}>
                Vous pouvez également me suivre sur{' '}
                <Link
                    href="https://www.youtube.com/@Saami783/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="secondary"
                >
                    ma chaîne YouTube
                </Link>
                {' '}pour découvrir mes derniers contenus.
            </p>
        </div>
    );
}

export default Contact;