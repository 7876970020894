import React from 'react';
import techIcons from './Icons/techIcons';
import '../../css/card.css';
import '../../css/projectCard.css';

import { FaFilePdf, FaGithub, FaYoutube } from "react-icons/fa";
const ProjectCard = ({ project }) => {
    return (
        <div>
            <a href={project.link} target="_blank" rel="noopener noreferrer">
                <img src={project.imageURL} alt={project.title}/>
                <h3>{project.title}</h3>
            </a>
            <p>
                {project.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br/>
                    </React.Fragment>
                ))}
            </p>
            <div>
                {project.technologies.map((tech, index) => {
                    const Icon = techIcons[tech];
                    return Icon ? <Icon key={index} className="icons_cards"/> : <span key={index}>{tech}</span>;
                })}
            </div>
            <br/>
            {project.category === "C" && project.pdfUrl && (
                <div className="icon-container">
                    <a href={project.github} className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaGithub className="icon" />
                    </a>
                    <a href={project.pdfUrl} className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaFilePdf className="icon" />
                    </a>
                    <a href={project.youtube} className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaYoutube className="icon" />
                    </a>
                </div>
            )}

            {project.category === "Python" && project.pdfUrl && (
                <div className="icon-container">
                    <a href={project.pdfUrl} className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaFilePdf className="icon" />
                    </a>
                </div>
            )}

        </div>
    );
};

export default ProjectCard;

