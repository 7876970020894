// techIcons.js
import { FaReact, FaHtml5, FaCss3Alt, FaSymfony, FaLaravel, FaJava } from 'react-icons/fa';
import { BiLogoSpringBoot, BiLogoPython } from "react-icons/bi";
import { SiApachetomcat, SiPhp, SiMicrosoftazure } from "react-icons/si";
// import { Tb2Fa } from "react-icons/tb";
import { MdAdminPanelSettings, MdBiotech } from "react-icons/md";
import { VscFileBinary } from "react-icons/vsc";
import { IoIosStats } from "react-icons/io";
import {BiLogoTailwindCss} from "react-icons/bi";

const techIcons = {
    "React": FaReact,
    "PHP": SiPhp,
    "HTML": FaHtml5,
    "CSS": FaCss3Alt,
    "Symfony": FaSymfony,
    "Laravel": FaLaravel,
    "Microsoft": SiMicrosoftazure,
    "Java": FaJava,
    "Springboot": BiLogoSpringBoot,
    "Tomcat": SiApachetomcat,
    // "2FA": Tb2Fa,
    "Admin": MdAdminPanelSettings,
    "Biologie": MdBiotech,
    "Stats": IoIosStats,
    "Python": BiLogoPython,
    "Binary": VscFileBinary,
    "Tailwind": BiLogoTailwindCss,
};

export default techIcons;
